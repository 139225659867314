<template>
  <h1>{{ $t("Generic.Redirecting") }}</h1>
</template>

<script>
export default {
  created() {
    window.location.replace(
      baseUrl +
        "/images/ffc98e46-f3c7-453d-fae5-08d9324d06c2/bc5f9e43-86c8-41b3-b0f5-34862d4364ac__Screenshot 2021-09-07 at 12.02.43.png"
    );
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_APIURL,
    };
  },
};
</script>
